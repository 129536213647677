import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class DozenBearInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            The bees have forsaken their Queen and want to keep all the honey
            for themselves. Fill 12 jars of honey, bring it back to the Queen
            and watch her reclaim her throne once again.
            <br /> In this simple metroidvania you play as a bear that only has
            a dozen moves to find the nearest checkpoint, but worry not, you’ll
            find some friendly bears that are willing to assist you on your
            quest by lending you their powers. <br />
            <br />
            Created by eddynardo.
            <br />
            Song “The Black Kitty” by Rolemusic under a CC BY 4.0 attribution
            licence.
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Controls:</u>
            <br />
            Keyboard
            <br />
            Move: WASD or Arrow keys
            <br />
            Map: S or Down Arrow key (when you are at a checkpoint)
            <br />
            Menu: Esc
            <br />
            The game autosaves at every checkpoint.
            <br />
            Try speedrun mode if you want to play the game from the beginning.
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
